@import "../../../../units/mixins";

.task{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: opacity 800ms;
  opacity: 1;
  & .task__title{
    width: 100%;
    @include flex-space-center();
    padding: 21px 0;
    position: relative;
    margin-bottom: 27px;
    & .task__title_description{
      @include font-setter(normal,normal, 16px, 19px, #282E3A);
    }
    & .task__title_title{
      text-transform: uppercase;
      @include font-setter(normal,bold, 20px, 24px, #282E3A);
    }
    &:after{
      content: '\A';
      width: calc(100% + 42px);
      left: -21px;
      bottom: 0;
      height: 2px;
      position: absolute;
      background: #9CD3EC;
      opacity: 0.5;

    }
  }
  & .task__tasks{
    // display: grid;
    // grid-template-columns: 596px 594px;
    // grid-auto-flow: column;
    // column-gap: 100px;
    column-count: 2;
    // display: -ms-grid;
    // -ms-grid-columns: 594px 20px 594px;
  }
  & .task__main{
    background: #DFF0F8;
    border-radius: 20px;
    position: relative;
    padding: 21px 21px 23px;
    box-sizing: border-box;
    & .task__main_close{
      background: none;
      &:hover{
        transform: rotate(90deg);
      }
      border: none;
      cursor: pointer;
      position: absolute;
      right: -40px;
      top: 0;
    }
  }
  & .task__answered {
    background-color: darken($color: #DFF0F8, $amount: 12%);
  }
  & .task__buttons{
    @include flex-start-center();
    width: 100%;
  }
  & .task__buttons_next{
    cursor: pointer;
    @include clear();
    width: 262px;
    &:hover{
      box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
    }
    height: 34px;
    margin-top: 14px;
    @include border-radius(100px);
    background: #4DB0DD;
    @include font-setter(normal,bold, 14px, 17px, #FFF);
    text-transform: uppercase;
  }
}

.taskField{
  @include flex-start-start();
  margin-bottom: 8px;
  & .taskField__text{
    margin-left: 11px;
    text-align: left;
    @include font-setter(normal,normal, 12px, 15px, #282E3A);
  }
}
@media  (max-width: 1024px){
  .task {
    color: red !important;
    .task__tasks {
      column-count: 1;
    }
    .taskField{
      & .taskField__text{
        max-width: 90%;
      }
    }
  }
}

@media  (max-width: 830px){
  .task {
    .task__main_close{
      right: -10px;
    }
    .task__main {
      width: 80%;
    }
  }
}

@media  (max-width: 400px){
  .task {
    .task__main_close{
      right: -20px !important;
      top: -10px !important;
    }
    .task__main {
      width: 80%;
    }
    .taskField__text{
      max-width: 80% !important;
    }
  }
  .compare__select_button {
    font-size: 12px !important;
    line-height: 15px !important;
  }
  .compareTitle__text {
    font-size: 14px !important;
    line-height: 18px !important;
    margin: 30px 0 !important;
  }
  .compareBack {
    margin-top: 30px !important;
  }
}

.compare{
  width: 812px;
  & .compare__select{
    & .compare__select_button{
      @include clear();
      @include border-radius(10px);
      @include font-setter(normal,bold, 16px, 19px, #282E3A);
      cursor: pointer;
      width: 100%;
      max-width: 565px;
      padding: 0 12px;
      &:hover{
        box-shadow: 0px 10px 40px rgba(255, 255, 255, 0.4), 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
      }
      height: 64px;
      background: #FFFFFF;
    }
    & .compare__select_text{

      @include font-setter(normal,bold, 16px, 19px, #282E3A);
      margin: 20px 0;
    }
  }
}

.compareBack{
  @include clear();
  @include border-radius(100px);
  @include font-setter(normal,bold, 14px, 17px, #4DB0DD);
  border: 1px solid #4DB0DD;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 70px;
  width: 100%;
  &:hover{
    box-shadow: 0px 10px 40px rgba(77, 176, 221, 0.4), 0px 0px 0px 4px rgba(77, 176, 221, 0.4);
  }
  max-width: 262px;
  height: 54px;
}

.compareTitle{
  & .compareTitle__text{
    white-space: pre-wrap;
    width: 100%;
    @include font-setter(normal,bold, 20px, 24px, #282E3A);
    margin: 60px 0;
  }
}

.activeSelect{
  animation-duration: 1s;
  animation-name: pulsar;
}

@keyframes pulsar {
  0% {
    background: #FFF ;
    color: #282E3A;
  }
  50% {
    background: #4DB0DD ;
    color: #FFF ;
  }
  100% {
    background: #FFF ;
    color: #282E3A;
  }
}

.hide{
  opacity: 0;
}
