@import "../../units/mixins";

.modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  //background: rgba(16,55,73,0.6);
  @include flex-center();
  z-index: 2;
  & .modal__content{
    z-index: 3;
    width: 810px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-bottom: 10px;
    & .modal__title{
      height: 107px;
      padding: 0 45px;
      @include flex-center();
      box-sizing: border-box;
      border-bottom: 2px solid #E9EAEB;
      & .modal__title_title{
        @include font-setter(normal, bold, 24px,29px, #282E3A);
        text-transform: uppercase;
      }
      & .modal__title_text{
        white-space: nowrap;
        @include font-setter(normal, normal, 20px,24px, #282E3A);
      }
    }
    & .modalContent{
      margin-top: 15px;
      margin-bottom: 15px;
      padding-bottom: 40px;
      @include flex-start-center();
      flex-direction: column;
      max-height: calc(100vh - 210px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
        border-radius: 20px;
      }
      & .modalContent__text{
        max-width: 720px;
        @include font-setter(normal, normal, 20px,24px, #000000);
        margin-bottom: 80px;
      }
      & .modalContent__textCenter {
        text-align: center;
      }
      & .modalContent__textMore{
        max-width: 720px;
        @include font-setter(normal, normal, 20px,24px, #000000);
        margin-bottom: 20px;
      }
      & .modalContent__textLittle{
        padding-top: 10px;
        border-top: 2px solid #E9EAEB;
        max-width: 720px;
        @include font-setter(normal, normal, 14px, 18px, #000000);
        margin-bottom: 20px;
      }
      & .modalContent__button{
        @include clear();
        &:hover{
          box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
        }
        padding: 0 10px;
        width: 530px;
        min-height: 74px;
        background: #4DB0DD;
        border-radius: 50px;
        @include font-setter(normal, bold, 22px,27px, #F4F4F4);
        text-transform: uppercase;
      }
    }

  }
  
  & .backGround {
    background: rgba(16,55,73,0.6);
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
.instruction{
  justify-content: space-between !important;
}

//@media all and (max-width: 700px){
//  .modal{
//    height: 100%;
//   position: relative;
//  }
//  .modal__title_title{
//    font-size: 20px!important;
//    line-height: 23px!important;
//  }
//  .modalContent__text{
//    max-width: 80%;
//    font-size: 14px!important;
//    line-height: 16px!important;
//  }
//  .modal__title{
//    padding: 0 10px !important;
//  }
//  .modalContent{
//    margin: 0 0 20px !important;
//
//    & .modalContent__text{
//      margin: 20px 0 ;
//    }
//  }
//  .modal__content{
//    position: sticky;
//    top: 0;
//    width: 90% ;
//  }
//  .modalContent__button{
//    width: 240px ;
//    font-size: 14px;
//    line-height: 16px;
//    height: 40px ;
//  }
//  .modalContent__text{
//    width: 80% ;
//  }
//  .exitContainer{
//    width: 240px ;
//    margin-right: 20px ;
//  }
//  .exitContainer__close, .exitContainer__save{
//    border-radius: 50px;
//    height: 40px ;
//    font-size: 14px;
//    line-height: 16px;
//  }
//}
@media all and (max-width: 400px){
  .modal{
    //height: 100%;
    position: relative;
    //padding: 100px 0 !important;
  }
}

@media all and (max-width: 850px){
  .modalContent {
    margin-top: 5px !important;
  }
  .modal__title {
    height: 50px !important;
  }
}

@media all and (max-width: 750px){
  .modal{
    height: 95vh;
    position: relative;
    padding: 0px 0;
  }
  .modal__title {
    height: 50px !important;
  }
  .modal__title_title{
    font-size: 15px!important;
    line-height: 20px!important;
  }
  .modalContent__text{
    max-width: 80%;
    font-size: 12px!important;
    line-height: 15px!important;
  }
  .modalContent__textMore {
    max-width: 80%;
    font-size: 14px!important;
    line-height: 18px!important;
    margin-bottom: 0 !important;
  }
  .modalContent {
    padding: 10px;
  }
  .modal__title{
    padding: 0 30px !important;
    .modal__title_text{
      font-size: 18px !important;
    }
  }
  .modalContent{
    margin: 0 0 10px !important;
    padding-bottom: 15px !important;
    & .modalContent__text{
      margin: 20px 0 !important;
      text-align: center;
    }
  }
  .modal__content{
    //position: sticky;
    //top: 0;
    width: 90% !important;
  }
  .modalContent__button{
    width: 240px !important;
    font-size: 11.5px!important;
    line-height: 13px!important;
    height: 35px !important;
    min-height: 35px !important;
  }
  .modalContent__text{
    width: 80% !important;
  }
  .exitContainer{
    width: 240px !important;
    margin-right: 30px !important;
  }
  .exitContainer__close, .exitContainer__save{
    border-radius: 50px;
    height: 40px !important;
    font-size: 17px!important;
    line-height: 20px!important;
  }
}

@media all and (max-width: 475px) {
  .modalContent__button {
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

@media all and (max-width: 320px) {
  .modal__title {
    padding: 0 10px !important;
    .modal__title_title {
      font-size: 12px!important;
      line-height: 15px!important;
    }
    .modal__title_text {
      font-size: 12px!important;
      line-height: 15px!important;
    }
  }
  .modalContent__text{
    max-width: 80%;
    font-size: 12px!important;
    line-height: 15px!important;
  }
  .modalContent__textMore {
    max-width: 80%;
    font-size: 12px!important;
    line-height: 15px!important;
    margin-bottom: 0 !important;
  }
  .modalContent__textLittle {
    font-size: 12px !important;
    line-height: 15px !important;
  }
  .modalContent__button {
    font-size: 11px!important;
    line-height: 13px!important;
  }
}

.exitContainer{
  width: 100%;
  max-width: 468px;
  display: grid;
  box-sizing: border-box;
  grid-column-gap: 20px;
  grid-template-columns: 50% 50%;
  & .exitContainer__close{
    &:hover{
      box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
    }
    @include clear();
    background: #DFF0F8;
    border-radius: 50px;
    height: 74px;
    width: 100%;
    @include font-setter(normal, bold, 22px,27px, #282E3A);
  }
  & .exitContainer__save{
    &:hover{
      box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
    }
    @include clear();
    background: #4DB0DD;
    border-radius: 50px;
    height: 74px;
    width: 100%;
    @include font-setter(normal, bold, 22px,27px, #FFF);

  }
}
