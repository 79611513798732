@import "../../../../units/mixins";

.firstTask{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  & .firstTask__main{
    width: 986px;
    background: #DFF0F8;
    border-radius: 20px;
    position: relative;
    padding: 19px 19px 47px;
    box-sizing: border-box;
    & .firstTask__main_close{
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      &:hover{
        transform: rotate(90deg);
      }
      right: -40px;
      top: 0;
    }
    & .firstTask__main_title{
      padding-top: 39px;
      padding-bottom: 43px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      text-transform: uppercase;
      color: #282E3A;
    }
    & .selector{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .selector__type, & .selector__typeMobile{
        font-style: normal;
        font-weight: 600;
        text-align: right;
        color: #282E3A;
        opacity: 0.5;
      }
      & .selector__button{
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        &:hover{
          box-shadow: 0px 10px 40px rgba(255, 255, 255, 0.4), 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
        }
        align-items: center;
        justify-content: center;
        cursor: pointer;
        & .selector__button_value{
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
          text-align: center;
          border-radius: 6px;
          color: #282E3A;
        }
      }
      //& .selector__button:hover .selector__button_value{
      //  background: #4DB0DD ;
      //  color: #FFF ;
      //  border-radius: 6px;
      //}
    }
    & .selector__typeMobile{
      display: none;
    }
  }
  & .firstTask__answers{
    margin-top: 34px;
    min-height: 315px;
    width: 986px;
    background: #FFFFFF;
    border: 2px solid #E9EAEB;
    box-sizing: border-box;
    border-radius: 20px;
    & .answerTitle{
      height: 82px;
      border-bottom: 2px solid #E9EAEB;
      box-sizing: border-box;
      @include flex-center();
      flex-direction: column;
      & .answerTitle__text{
        @include font-setter(normal, bold, 18px, 22px, #282E3A);
      }
      & .answerTitle__description{
        opacity: 0.6;
        @include font-setter(normal, normal, 16px, 19px, #282E3A);
      }

    }
    & .answerTypes{
      padding: 5px 13px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      //@include flex-start-start();
      flex-wrap: wrap;
      & .answerTypes__tag{
        margin-top: 10px;
        @include clear();
        margin-right: 10px;
        padding: 4px 8px;
        background: #DFF0F8;
        border-radius: 20px;
        &:hover{
          box-shadow: 0px 10px 40px rgba(223, 240, 248, 0.4), 0px 0px 0px 4px rgba(223, 240, 248, 0.4);
        }
        cursor: pointer;
        @include font-setter(normal, normal, 14px, 17px, #282E3A);
      }

    }
  }
}

@media  (max-width: 790px){
  .selector__type{
    padding: 0!important;
    font-size: 16px ;
    line-height: 19px ;
  }
  .selector__button {
    height: 30px !important;
    width: 30px !important;

    & .selector__button_value {
      font-size: 14px !important;
      height: 24px !important;
      width: 24px !important;
    }
  }
}
@media  (max-width: 635px){
  .selector__type{
    font-size: 12px !important;
    line-height: 15px !important;
    display: none;
  }
  .firstTask__main_close{
    right: -20px !important;
    top: -10px !important;
  }
  .selector__typeMobile{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 12px !important;
    line-height: 15px !important;
  }
  .firstTask__main_title {
    font-size: 18px !important;
    line-height: 20px !important;
  }
}
@media  (min-width: 1101px){
  .selector__button {
    height: 74px ;
    width: 74px ;

    & .selector__button_value {
      height: 64px;
      width: 64px;
    }
  }
  .selector__type{
    padding: 0 16px;
    font-size: 22px;
    line-height: 27px;
  }
}
@media  (max-width: 1100px){
  .firstTask {
    .firstTask__main{
      width: 80%;
    }
    .selector__button {
      height: 50px ;
      width: 50px ;

      & .selector__button_value {
        height: 40px ;
        width: 40px;
      }
    }
  }
  .firstTask__answers{
    width: 80% !important;
  }
  .selector__type{
    padding: 0;
    font-size: 16px ;
    line-height: 19px;
  }
}
.activeSelect{
  animation-duration: 300ms;
  animation-name: pulsar;
}

@keyframes pulsar {
  0% {
    background: #FFF ;
    color: #282E3A;
  }
  50% {
    background: #4DB0DD ;
    color: #FFF ;
  }
  100% {
    background: #FFF ;
    color: #282E3A;
  }
}
