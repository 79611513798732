@import "../../../../units/mixins";

.task{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  & .task__title{
    width: 100%;
    @include flex-space-center();
    padding: 21px 0;
    position: relative;
    margin-bottom: 27px;
    & .task__title_description{
      @include font-setter(normal,normal, 16px, 19px, #282E3A);
    }
    & .task__title_title{
      text-transform: uppercase;
      @include font-setter(normal,bold, 20px, 24px, #282E3A);
    }
    &:after{
      content: '\A';
      width: calc(100% + 42px);
      left: -21px;
      bottom: 0;
      height: 2px;
      position: absolute;
      background: #9CD3EC;
      opacity: 0.5;

    }
  }
  & .task__tasks{
    @include flex-start-start();
    flex-direction: column;
  }
  & .task__main{
    width: 986px;
    background: #DFF0F8;
    border-radius: 20px;
    position: relative;
    padding: 21px 21px 23px;
    box-sizing: border-box;
    & .task__main_close{
      background: none;
      border: none;
      &:hover{
        transform: rotate(90deg);
      }
      cursor: pointer;
      position: absolute;
      right: -40px;
      top: 0;
    }
  }
  & .task__answered {
    background-color: darken($color: #DFF0F8, $amount: 13%);
  }
  & .task__buttons{
    @include flex-start-center();
    width: 100%;
  }
  & .task__buttons_next{
    cursor: pointer;
    @include clear();
    &:hover{
      box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
    }
    width: 262px;
    height: 54px;
    margin-top: 27px;
    @include border-radius(100px);
    background: #4DB0DD;
    @include font-setter(normal,bold, 14px, 17px, #FFF);
    text-transform: uppercase;
  }
}

.taskField{
  @include flex-start-start();
  margin-bottom: 16px;
  width: 97%;
  & .taskField__text{
    margin-left: 22px;
    text-align: left;
    max-width: 100%;
    max-width: 880px;
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;      /* IE */
    @include font-setter(normal,normal, 16px, 19px, #282E3A);
  }
}

@media  (max-width: 1200px){
  .task {
    .task__main{
      width: 80%;
    }
  }
  .task__main_close{
    right: -20px !important;
    top: -10px !important;
  }
  .taskField{
    .taskField__text{
      max-width: 95% !important;
    }
  }
}

@media (max-width: 720px) {
  .task__title_title {
    font-size: 16px !important;
    line-height: 18px !important;
  }
  .task__title_description {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .taskField {
    & .taskField__text {
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }
}

@media (max-width: 475px) {
  .taskField {
    & .taskField__text {
      font-size: 12px !important;
      line-height: 14px !important;
    }
  }
  .task__buttons {
    justify-content: center !important;
  }
}

@media (max-width: 340px) {
  .taskField {
    & .taskField__text {
      font-size: 11px !important;
      line-height: 12px !important;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .task__title_description {
    margin-left: 700px;
  }
  @media  (max-width: 1200px) {
    .task__title_description {
      margin-left: 100px;
    }
  }
}
