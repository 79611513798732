@import "../../units/mixins";
.header{
  height: 92px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  .header__imageContainer {
    position: relative;
    height: 40px;
    width: 40px;
  }
  .header__logo_image{
    position: absolute;
    top: -15px;
    left: 0;
    max-width: 150px;
    max-height: 70px;
  }
  & .header__logo{
    min-width: 40px;
    color: #4DB0DD;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    text-align: center;
  }
  & .header__stages{
    width: 718px;
    @include flex-space-center();
    & .header__stages_button{
      width: 206px;
      background: none;
      height: 40px;
      display: flex;
      //cursor: pointer;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border: 1px solid #4DB0DD;
      border-radius: 30px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      color: #4DB0DD;
    }
  }
  & .header__center {
    @include flex-center;
    // margin-left: 40px;
  }
  & .header__FAQ{
    height: 40px;
    width: 40px;
    background: #DFF0F8;
    cursor: pointer;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    &:hover{
      box-shadow: 0px 10px 40px rgba(223, 240, 248, 0.4), 0px 0px 0px 4px rgba(223, 240, 248, 0.4);
    }
    border: none;
    align-items: center;
  }
  & .header__FAQLighted {
    box-shadow: 0px 10px 40px rgb(90, 195, 240), 0px 0px 0px 3px rgba(39, 169, 230, 0.7);
  }
  & .shortText{
    display: none;
  }
}
.activeStage{
  border: none !important;
  background: #DFF0F8 !important;
  color: #282E3A !important;
}
@media all and (max-width: 1100px){
  .header{
    .header__logo_image{
      top: 0;
      max-height: 40px;
    }
    & .header__stages{
      width: 510px;
      & .header__stages_button{
        width: 150px;
      }
    }
  }
}
@media all and (max-width: 730px){
  .header{
    & .header__stages{
      width: 400px;
      & .header__stages_button{
        width: 120px;
      }
    }
  }
}
@media all and (max-width: 650px){
  .header {
    .header__logo_image{
      max-width: 70px;
      max-height: 40px;
    }
  }
}
@media all and (max-width: 590px){
  .header{
    .header__logo{
      font-size: 16px;
    }
    
      padding: 0 20px ;
    .longText{
      display: none;
    }
    .shortText{
      display: block;
    }
    & .header__stages{
      width: 180px;
      & .header__stages_button{
        width: 40px !important;
      }
      & .hintOpen {
        width: 62px !important;
        font-size: 8px;
        line-height: 14px;
      }
    }
    .header__center {
      & .header__stages_button{
        width: 120px !important;
      }
    }
  }
}

@media all and (max-width: 450px) {
  .header {
    .header__logo_image {
      max-width: 40px;
    }
    .header__center {
      .longText{
        display: none;
      }
      .shortText{
        display: block !important;
      }
      & .header__stages_button{
        width: 40px !important;
      }
      & .hintOpen {
        width: 62px !important;
        font-size: 8px;
        line-height: 14px;
      }
    }
  }
}

@media all and (max-width: 340px) {
  .header {
    .header__logo_image {
      max-width: 40px;
    }
  }
}