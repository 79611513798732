@mixin flex-space-center(){
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin clear(){
  background: none;
  border: none;
}
@mixin font-setter($style,$weight,$size,$height,$color){
  font-family: "Proxima Nova", sans-serif;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}
@mixin font-import($font){
  src: url("../style/Gilroy-Font/Gilroy-"+ $font + ".ttf");
  src: url("../style/Gilroy-Font/Gilroy-"+ $font + ".eot") format('eot'),
  url("../style/Gilroy-Font/Gilroy-"+ $font + ".woff") format('woff');
}
@mixin flex-center(){
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flexible-row(){
  flex-direction: row;
  display: flex;
  align-items: center;
}
@mixin font-button($size){
  font-style: normal;
  font-weight: 600;
  font-size: $size;
  line-height: 24px;
  text-align: right;
  color: #292929;
}
@mixin border-radius($radius){
  border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  -webkit-border-radius: $radius;
}

.authorizationPage{
  display: flex;
  .container {
    min-height: 100vh;
    min-height: calc(max(100vh, 600px));
    width: 100%;
    @include flex-center();
  }
  & .authorizationContent__logo_image{
    max-width: 50%;
    margin-bottom: 39px;
    max-height: 80px;
  }
  & .authorizationContent{
    width: 700px;
    //max-height: 100vh;
    box-sizing: border-box;
    background: #DFF0F8;
    @include border-radius(20px);
    padding: 64px 85px 55px;
    @include flex-space-center();
    flex-direction: column;
    justify-content: flex-start;
    min-height: fit-content;
    & .authorizationContent__title{
      @include font-setter(normal, 600, 30px, 36px, #282E3A);
      margin-bottom: 16px;
      text-align: center;
      text-transform: uppercase;
    }
    & .authorizationContent__logo{
      @include font-setter(normal, 600, 30px, 36px, #4DB0DD);
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 39px;
    }
    & .authorizationContent__passwordLabel{
      @include font-setter(normal, normal, 20px, 24px, #282E3A);
      margin-bottom: 20px;
    }
    & .authorizationContent__passwordInput{
      @include clear();
      box-sizing: border-box;
      width: 100%;
      height: 74px;
      background: #FFFFFF;
      @include border-radius(10px);
      margin-bottom: 22px;
      text-align: center;
      @include font-setter(normal,bold, 30px,34px, #282E3A);
    }
    & .authorizationContent__passwordInput::placeholder{
      @include font-setter(normal,bold, 30px,74px, #c2c8c8);
      vertical-align: middle;
      //transform: translateY(-50%);
    }
    & .authorizationContent__button{
      @include clear();
      box-sizing: border-box;
      width: 100%;
      height: 74px;
      background: #4DB0DD;
      cursor: pointer;
      &:hover{
        box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
      }
      @include border-radius(50px);
      @include font-setter(normal, 700, 22px, 27px, #FFF);
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 40px;
    }
    & .authorizationContent__helloText{
      width: 450px;
      margin-bottom: 50px;
      @include font-setter(normal, 400, 20px, 27px, #000);
      & .authorizationContent__helloText_title{
        @include font-setter(normal, 700, 20px, 27px, #000);
      }
      &_description {
        white-space: pre-line;
      }
    }
    & .authorizationContent__helpButton{
      @include clear();
    }
    & .authorizationContent__helpButton_bold{
      cursor: pointer;
      @include font-setter(normal, bold, 20px, 24px, #282E3A);
    }
    & .authorizationContent__helpButton_text{
      @include font-setter(normal, normal, 20px, 24px, #282E3A);
    }
 }
}

@media all and (max-width: 700px){
  .authorizationContent{
    padding: 30px 10px 30px !important;
    width: 100% !important;
    box-sizing: border-box;
    margin: 20px;
    // max-height: 100vh !important;
    // min-height: 200px !important;
    border-radius: 50px;
  }

  .authorizationContent__title {
    font-size: 25px !important;
    line-height: 25px !important;
  }

  .authorizationContent__passwordInput {
    font-size: 25px !important;
    line-height: 25px !important;
    &::placeholder {
      font-size: 25px !important;
      line-height: 25px !important;
    }
  }

  .authorizationContent__button{
    margin-bottom: 10px !important;
  }

  .authorizationContent__helloText{
    width: 100% !important;
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

.errorMessage{
  font-family: "Proxima Nova",sans-serif;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #db3d3d;
}

.withoutPassword{
  justify-content: space-between !important;
  height: auto !important;
  min-height: auto !important;
}
