@mixin flex-space-center(){
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin clear(){
  background: none;
  border: none;
}
@mixin font-setter($style,$weight,$size,$height,$color){
  font-family: "Proxima Nova", sans-serif;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
  color: $color;
}
@mixin font-import($font){
  src: url("../style/Gilroy-Font/Gilroy-"+ $font + ".ttf");
  src: url("../style/Gilroy-Font/Gilroy-"+ $font + ".eot") format('eot'),
  url("../style/Gilroy-Font/Gilroy-"+ $font + ".woff") format('woff');
}
@mixin flex-center(){
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flexible-row(){
  flex-direction: row;
  display: flex;
  align-items: center;
}
@mixin font-button($size){
  font-style: normal;
  font-weight: 600;
  font-size: $size;
  line-height: 24px;
  text-align: right;
  color: #292929;
}
@mixin border-radius($radius){
  border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  -webkit-border-radius: $radius;
}
@mixin flex-start-start(){
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
@mixin  flex-start-center(){
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin hover-button() {
  box-shadow: 0px 10px 40px rgba(74, 164, 214, 0.4), 0px 0px 0px 4px rgba(74, 164, 214, 0.4);
}
