
.App-safari {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  padding:0;
  margin:0;
/* if you want the content to scroll normally: */
  overflow: auto;
  /* cosmetic stuff: */
  /* background-color:#AEA;
  border: #6B6 1em solid;
  box-sizing:border-box; */
}

.overflowHidden {
  overflow: hidden;
}

#root,.App {
  text-align: center;
  /* min-height: 100vh; */
  /* height: 100%; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiLinearProgress-root{
  height: 10px !important;
  background-color: #FFF !important;
  border-radius: 15px;
}
.MuiLinearProgress-bar{
  background-color: #39A7D9 !important;
  opacity: 0.5 !important;
  border-radius: 15px !important;
}

*{
  transition: all 0.4s ease-out;
}
/*.activeSelect{*/
/*  animation-duration: 1s;*/
/*  animation-name: pulsar;*/
/*}*/

/*@keyframes pulsar {*/
/*  0% {*/
/*    background: #FFF !important;*/
/*    color: #282E3A!important;*/
/*  }*/
/*  25% {*/
/*    background: #4DB0DD !important;*/
/*    color: #FFF !important;*/
/*  }*/
/*  50% {*/
/*    background: #FFF !important;*/
/*    color: #282E3A!important;*/
/*  }*/
/*  75% {*/
/*    background: #4DB0DD !important;*/
/*    color: #FFF !important;*/
/*  }*/
/*  1000% {*/
/*    background: #FFF !important;*/
/*    color: #282E3A!important;*/
/*  }*/
/*}*/
