/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNovaCond-LightIt.eot');
	src: local('Proxima Nova Condensed Light Italic'), local('ProximaNovaCond-LightIt'),
		url('ProximaNovaCond-LightIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-LightIt.woff') format('woff'),
		url('ProximaNovaCond-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-LightIt.woff') format('woff'),
		url('ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNovaCond-Regular.eot');
	src: local('Proxima Nova Condensed Regular'), local('ProximaNovaCond-Regular'),
		url('ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Regular.woff') format('woff'),
		url('ProximaNovaCond-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova-Extrabld.eot');
	src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
		url('ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Extrabld.woff') format('woff'),
		url('ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Cn Bl';
	src: url('ProximaNovaCond-Black.eot');
	src: local('Proxima Nova Condensed Black'), local('ProximaNovaCond-Black'),
		url('ProximaNovaCond-Black.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Black.woff') format('woff'),
		url('ProximaNovaCond-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNovaCond-ExtrabldIt.eot');
	src: local('Proxima Nova Condensed Extrabold Italic'), local('ProximaNovaCond-ExtrabldIt'),
		url('ProximaNovaCond-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-ExtrabldIt.woff') format('woff'),
		url('ProximaNovaCond-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('ProximaNova-Regular.eot');
	src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
		url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Regular.woff') format('woff'),
		url('ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-Semibold.eot');
	src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
		url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Semibold.woff') format('woff'),
		url('ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNovaExCn-Thin.eot');
	src: local('Proxima Nova Extra Condensed Thin'), local('ProximaNovaExCn-Thin'),
		url('ProximaNovaExCn-Thin.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Thin.woff') format('woff'),
		url('ProximaNovaExCn-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNovaCond-Semibold.eot');
	src: local('Proxima Nova Condensed Semibold'), local('ProximaNovaCond-Semibold'),
		url('ProximaNovaCond-Semibold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Semibold.woff') format('woff'),
		url('ProximaNovaCond-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNovaExCn-SemiboldIt.eot');
	src: local('Proxima Nova Extra Condensed Semibold Italic'), local('ProximaNovaExCn-SemiboldIt'),
		url('ProximaNovaExCn-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-SemiboldIt.woff') format('woff'),
		url('ProximaNovaExCn-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNovaExCn-Semibold.eot');
	src: local('Proxima Nova Extra Condensed Semibold'), local('ProximaNovaExCn-Semibold'),
		url('ProximaNovaExCn-Semibold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Semibold.woff') format('woff'),
		url('ProximaNovaExCn-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova-ThinIt.eot');
	src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
		url('ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-ThinIt.woff') format('woff'),
		url('ProximaNova-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNovaCond-Thin.eot');
	src: local('Proxima Nova Condensed Thin'), local('ProximaNovaCond-Thin'),
		url('ProximaNovaCond-Thin.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Thin.woff') format('woff'),
		url('ProximaNovaCond-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNovaCond-RegularIt.eot');
	src: local('Proxima Nova Condensed Regular Italic'), local('ProximaNovaCond-RegularIt'),
		url('ProximaNovaCond-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-RegularIt.woff') format('woff'),
		url('ProximaNovaCond-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNovaExCn-ThinIt.eot');
	src: local('Proxima Nova Extra Condensed Thin Italic'), local('ProximaNovaExCn-ThinIt'),
		url('ProximaNovaExCn-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-ThinIt.woff') format('woff'),
		url('ProximaNovaExCn-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-SemiboldIt.eot');
	src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
		url('ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-SemiboldIt.woff') format('woff'),
		url('ProximaNova-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('ProximaNova-RegularIt.eot');
	src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
		url('ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-RegularIt.woff') format('woff'),
		url('ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNovaCond-Extrabld.eot');
	src: local('Proxima Nova Condensed Extrabold'), local('ProximaNovaCond-Extrabld'),
		url('ProximaNovaCond-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Extrabld.woff') format('woff'),
		url('ProximaNovaCond-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNovaExCn-ExtrabldIt.eot');
	src: local('Proxima Nova Extra Condensed Extrabold Italic'), local('ProximaNovaExCn-ExtrabldIt'),
		url('ProximaNovaExCn-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-ExtrabldIt.woff') format('woff'),
		url('ProximaNovaExCn-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Bl';
	src: url('ProximaNovaExCn-BlackIt.eot');
	src: local('Proxima Nova Extra Condensed Black Italic'), local('ProximaNovaExCn-BlackIt'),
		url('ProximaNovaExCn-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-BlackIt.woff') format('woff'),
		url('ProximaNovaExCn-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNovaExCn-LightIt.eot');
	src: local('Proxima Nova Extra Condensed Light Italic'), local('ProximaNovaExCn-LightIt'),
		url('ProximaNovaExCn-LightIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-LightIt.woff') format('woff'),
		url('ProximaNovaExCn-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Th';
	src: url('ProximaNovaCond-ThinIt.eot');
	src: local('Proxima Nova Condensed Thin Italic'), local('ProximaNovaCond-ThinIt'),
		url('ProximaNovaCond-ThinIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-ThinIt.woff') format('woff'),
		url('ProximaNovaCond-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Lt';
	src: url('ProximaNovaExCn-Light.eot');
	src: local('Proxima Nova Extra Condensed Light'), local('ProximaNovaExCn-Light'),
		url('ProximaNovaExCn-Light.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Light.woff') format('woff'),
		url('ProximaNovaExCn-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNovaCond-BoldIt.eot');
	src: local('Proxima Nova Condensed Bold Italic'), local('ProximaNovaCond-BoldIt'),
		url('ProximaNovaCond-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-BoldIt.woff') format('woff'),
		url('ProximaNovaCond-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Th';
	src: url('ProximaNovaExCn-Extrabld.eot');
	src: local('Proxima Nova Extra Condensed Extrabold'), local('ProximaNovaExCn-Extrabld'),
		url('ProximaNovaExCn-Extrabld.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Extrabld.woff') format('woff'),
		url('ProximaNovaExCn-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNovaExCn-BoldIt.eot');
	src: local('Proxima Nova Extra Condensed Bold Italic'), local('ProximaNovaExCn-BoldIt'),
		url('ProximaNovaExCn-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-BoldIt.woff') format('woff'),
		url('ProximaNovaExCn-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('ProximaNova-Black.eot');
	src: local('Proxima Nova Black'), local('ProximaNova-Black'),
		url('ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Black.woff') format('woff'),
		url('ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('ProximaNova-Bold.eot');
	src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
		url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Bold.woff') format('woff'),
		url('ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNovaExCn-Bold.eot');
	src: local('Proxima Nova Extra Condensed Bold'), local('ProximaNovaExCn-Bold'),
		url('ProximaNovaExCn-Bold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Bold.woff') format('woff'),
		url('ProximaNovaExCn-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNovaT-Thin.eot');
	src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
		url('ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaT-Thin.woff') format('woff'),
		url('ProximaNovaT-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('ProximaNova-BoldIt.eot');
	src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
		url('ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-BoldIt.woff') format('woff'),
		url('ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Rg';
	src: url('ProximaNovaCond-Bold.eot');
	src: local('Proxima Nova Condensed Bold'), local('ProximaNovaCond-Bold'),
		url('ProximaNovaCond-Bold.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Bold.woff') format('woff'),
		url('ProximaNovaCond-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Bl';
	src: url('ProximaNovaExCn-Black.eot');
	src: local('Proxima Nova Extra Condensed Black'), local('ProximaNovaExCn-Black'),
		url('ProximaNovaExCn-Black.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Black.woff') format('woff'),
		url('ProximaNovaExCn-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNovaExCn-RegularIt.eot');
	src: local('Proxima Nova Extra Condensed Regular Italic'), local('ProximaNovaExCn-RegularIt'),
		url('ProximaNovaExCn-RegularIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-RegularIt.woff') format('woff'),
		url('ProximaNovaExCn-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNovaCond-Light.eot');
	src: local('Proxima Nova Condensed Light'), local('ProximaNovaCond-Light'),
		url('ProximaNovaCond-Light.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-Light.woff') format('woff'),
		url('ProximaNovaCond-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova-ExtrabldIt.eot');
	src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
		url('ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-ExtrabldIt.woff') format('woff'),
		url('ProximaNova-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Cn Bl';
	src: url('ProximaNovaCond-BlackIt.eot');
	src: local('Proxima Nova Condensed Black Italic'), local('ProximaNovaCond-BlackIt'),
		url('ProximaNovaCond-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-BlackIt.woff') format('woff'),
		url('ProximaNovaCond-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova ExCn Rg';
	src: url('ProximaNovaExCn-Regular.eot');
	src: local('Proxima Nova Extra Condensed Regular'), local('ProximaNovaExCn-Regular'),
		url('ProximaNovaExCn-Regular.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaExCn-Regular.woff') format('woff'),
		url('ProximaNovaExCn-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Lt';
	src: url('ProximaNova-Light.eot');
	src: local('Proxima Nova Light'), local('ProximaNova-Light'),
		url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-Light.woff') format('woff'),
		url('ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Cn Lt';
	src: url('ProximaNovaCond-SemiboldIt.eot');
	src: local('Proxima Nova Condensed Semibold Italic'), local('ProximaNovaCond-SemiboldIt'),
		url('ProximaNovaCond-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNovaCond-SemiboldIt.woff') format('woff'),
		url('ProximaNovaCond-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('ProximaNova-BlackIt.eot');
	src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
		url('ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('ProximaNova-BlackIt.woff') format('woff'),
		url('ProximaNova-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
